<template>
    <v-app>
        <v-card>
          <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12">
                        <h3>
                          Class Timetable Period
                        </h3>
                        <router-link :to="{name:'dashboard'}">
                            Dashboard
                        </router-link>
                        <router-link :to="{name:'time-table'}">
                            \ Class Timetable
                        </router-link>
                        \ Class Timetable Period
                    </div>
                    <div class="col-6 text-right">
                    </div>
                </div>
          </div>
            <v-card-text>
                <div class="col-12">
                    <div class="row mb-5" style="background-color:whitesmoke">
                        <div class="col-md-2 col-lg-2 col-sm-12">
                            <b>Class/Section Name:</b> {{ academic_class ? academic_class.title.toUpperCase() : '' }}
                            <br>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-12">
                            <b>Level :</b> {{ (academic_class && academic_class.level) ? academic_class.level.title : ''
                            }} <br>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-12">
                            <b>Program :</b> {{ (academic_class && academic_class.program) ?
                            academic_class.program.title : '' }} <br>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-12">
                            <b>Semester/Year :</b> {{ (academic_class && academic_class.grade) ?
                            academic_class.grade.title : '' }} <br>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-12">
                            <b>Batch :</b> {{academic_class.academic_year.title }} <br>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-12">
                            <b>Shift :</b> {{academic_class.shift }} <br>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-12" v-if="academicClassTimetable.id">
                            <b>Created At :</b> {{academicClassTimetable.created_at }} <br>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12" v-if="academicClassTimetable.id">
                            <b>Last Updated At :</b> {{academicClassTimetable.updated_at }} <br>
                        </div>

                    </div>

                </div>
                <v-row>
                    <v-col>
                        <v-text-field
                                v-model="academicClassTimetable.title"
                                outlined
                                dense
                                label="Enter Timetable name"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn @click="openWeekdayDialog()" depressed color="blue" class="text-white ">
                            Set class days
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-4">
            <v-card-text>
                <div class="table table-stripe">
                    <div class="title text-center">
                        Timetable for Week
                    </div>
                    <table class="table table-stripe">

                        <tbody class="">

                        <tr v-if="academicClassTimetable.is_working_sunday">
                            <td>Sunday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(0)">Assign</a></td>
                            <td v-for="(sunday,i) in academicClassTimetablePeriods" :key="'sunday'+i"
                                v-if="sunday.day == 0">
                                <strong> {{`${sunday.simple_start_time } - ${sunday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!sunday.is_break">
                                    {{sunday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{sunday.teacher_name}}</strong>

                            </td>
                        </tr>
                        <tr v-if="academicClassTimetable.is_working_monday">
                            <td>Monday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(1)">Assign</a></td>
                            <td v-for="(monday,i) in academicClassTimetablePeriods" :key="'monday'+i"
                                v-if="monday.day == 1">
                                <strong> {{`${monday.simple_start_time } - ${monday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!monday.is_break">
                                    {{monday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{monday.teacher_name}}</strong>

                            </td>
                        </tr>
                        <tr v-if="academicClassTimetable.is_working_tuesday">
                            <td>Tuesday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(2)">Assign</a>
                            </td>
                            <td v-for="(tuesday,i) in academicClassTimetablePeriods" :key="'tuesday'+i"
                                v-if="tuesday.day == 2">
                                <strong> {{`${tuesday.simple_start_time } - ${tuesday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!tuesday.is_break">
                                    {{tuesday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{tuesday.teacher_name}}</strong>

                            </td>
                        </tr>

                        <tr v-if="academicClassTimetable.is_working_wednesday">
                            <td>Wednesday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(3)">Assign</a>
                            </td>
                            <td v-for="(wednesday,i) in academicClassTimetablePeriods" :key="'wednesday'+i"
                                v-if="wednesday.day ==3">
                                <strong> {{`${wednesday.simple_start_time } - ${wednesday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!wednesday.is_break">
                                    {{wednesday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{wednesday.teacher_name}}</strong>

                            </td>

                        </tr>
                        <tr v-if="academicClassTimetable.is_working_thursday">
                            <td>Thursday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(4)">Assign</a>
                            </td>
                            <td v-for="(thursday,i) in academicClassTimetablePeriods" :key="'thursday'+i"
                                v-if="thursday.day == 4">
                                <strong> {{`${thursday.simple_start_time } - ${thursday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!thursday.is_break">
                                    {{thursday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{thursday.teacher_name}}</strong>
                            </td>
                        </tr>
                        <tr v-if="academicClassTimetable.is_working_friday">
                            <td>Friday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(5)">Assign</a></td>
                            <td v-for="(friday,i) in academicClassTimetablePeriods" :key="'friday'+i"
                                v-if="friday.day == 5">
                                <strong> {{`${friday.simple_start_time } - ${friday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!friday.is_break">
                                    {{friday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{friday.teacher_name}}</strong>

                            </td>
                        </tr>
                        <tr v-if="academicClassTimetable.is_working_saturday">
                            <td>Saturday <br> <a href="javascript:void(0)" @click="openTimeTableDialog(6)">Assign</a>
                            </td>
                            <td v-for="(saturday,i) in academicClassTimetablePeriods" :key="'saturday'+i"
                                v-if="saturday.day == 6">
                                <strong> {{`${saturday.simple_start_time } - ${saturday.simple_end_time} `}}</strong>

                                <br>

                                <strong v-if="!saturday.is_break">
                                    {{saturday.subject_name}}
                                </strong>
                                <strong v-else>
                                    Break
                                </strong>
                                <br>
                                <strong> {{saturday.teacher_name}}</strong>

                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="weekdayDialog" max-width="600">
            <v-card>
                <v-card-title>
                    Class days
                </v-card-title>
                <v-card-text>
                    <div class="table table-stripe">
                        <table class="table table-stripe">
                            <thead>
                            <th>Set class</th>
                            <th>Day</th>

                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_sunday"
                                    ></v-checkbox>
                                </td>
                                <td>Sunday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_monday"
                                    ></v-checkbox>
                                </td>
                                <td>Monday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_tuesday"
                                    ></v-checkbox>
                                </td>
                                <td>Tuesday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_wednesday"
                                    ></v-checkbox>
                                </td>
                                <td>Wednesday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_thursday"
                                    ></v-checkbox>
                                </td>
                                <td>Thursday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_friday"
                                    ></v-checkbox>
                                </td>
                                <td>Friday</td>
                            </tr>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetable.is_working_saturday"
                                    ></v-checkbox>
                                </td>
                                <td>Saturday</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-col class="text-right">
                        <v-btn @click="saveTimeTable()" depressed color="blue" class="text-white">
                            Save
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="timeTableDialog" max-width="900">
            <v-card>
                <v-card-title>
                    Set TimeTable
                </v-card-title>
                <v-card-text>
                    <div class="table table-stripe">
                        <table class="table table-stripe">
                            <thead>
                            <th>Is Break</th>
                            <th>Subject</th>
                            <th>Teacher</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Actions</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <v-checkbox v-model="academicClassTimetablePeriod.is_break"
                                    >Is Break
                                    </v-checkbox>
                                </td>
                                <td>
                                    <v-select v-if="!academicClassTimetablePeriod.is_break" outlined dense
                                              v-model="academicClassTimetablePeriod.subject_id"
                                              :items="subjects" label="Select Subject"
                                              item-value="id"
                                              item-text="title" return-object
                                              @change="getTeachers(academicClassTimetablePeriod.subject_id)"
                                                >
                                    </v-select>
                                    <strong v-else>
                                        Break
                                    </strong>
                                </td>
                                <td>
                                    <v-select v-if="!academicClassTimetablePeriod.is_break" outlined dense
                                              v-model="academicClassTimetablePeriod.teacher_id" :items="users"
                                              label="Select Teacher" item-value="id" item-text="full_name"
                                              return-object>
                                    </v-select>
                                    <strong v-else>
                                        Break
                                    </strong>
                                </td>
                                <td>
                                    <v-text-field outlined dense v- v-model="academicClassTimetablePeriod.start_time"
                                                  type="time"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field outlined dense v- v-model="academicClassTimetablePeriod.end_time"
                                                  type="time"></v-text-field>
                                </td>
                                <td>
                                    <v-btn @click="addTimeTable" depressed color="blue" class="text-white">
                                        Add Period
                                    </v-btn>
                                </td>
                            </tr>

                            <tr v-for="(item,i) in academicClassTimetablePeriods" :key="item.day"
                                v-if="item.day == currentDay">
                                <td v-if="!item.is_break" colspan="2">
                                    {{item.subject_name}}
                                </td>
                                <td v-if="!item.is_break">
                                    {{item.teacher_name}}
                                </td>
                                <td v-else colspan="3">
                                    <strong>Break</strong>
                                </td>
                                <td>
                                    {{item.start_time}}
                                </td>
                                <td>
                                    {{item.end_time}}
                                </td>
                                <td>
                                    <v-btn @click="removeTimeTable(i)"
                                           icon
                                           color="red"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-col class="text-right">
                        <v-btn depressed @click="saveTimeTablePeriod()" color="blue" class="text-white">
                            Save
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>

</template>

<script>
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import UserService from "@/core/services/user/UserService";
    import AcademicClassTeacherService from "@/core/services/academic-class/class-teacher/AcademicClassTeacherService";
    import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
    import AcademicCLassTimetablePeriodService
        from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";
    const academicClassTimetable = new AcademicCLassTimetableService();
    const academicClassService = new AcademicClassService();
    const userService = new UserService();
    const academicCLassTimetablePeriod = new AcademicCLassTimetablePeriodService();
    const academicClassTeacherService = new AcademicClassTeacherService();



    export default {
        name: "time-table",
        data() {

            return {
                time: null,
                currentDay: null,
                start_time: false,
                end_time: false,
                academicClassTimetable: {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 0,
                },
                academicClassTimetablePeriod: {
                    timetable_id: null,
                    day: null,
                    subject_id: null,
                    subject_name: null,
                    teacher_id: null,
                    teacher_name: null,
                    start_time: null,
                    end_time: null,
                    is_break: 0,
                },
                checkAvailiblity: {
                    teacher_id: null,
                    start_time: null,
                    end_time: null,
                },
                academicClassTimetablePeriods: [],
                subjects: [],
                weekdayDialog: false,
                timeTableDialog: false,
                assignClassDialog: false,
                academic_classes: [],
                academic_class: {
                    level_id: null,
                    program_id: null,
                    grade_id: null,
                    shift: null,
                    title: null,
                    is_active: true
                },
                assign_course_teachers: [],
                class: null,
                users: [],
                class_teachers: [],
                loading: false,
                search: {
                    type: 'teacher',
                    limit: 250,

                }, page: null,
            }
        },
        computed: {
            classId() {
                return this.$route.params.id;
            },
            formatDate() {
                return this.class.start_date.split('/').join('-');
            },

            // items() {
            //     const items = [];
            //     this.assign_course_teachers.forEach(item => {
            //         items.push({
            //             name: `${item.course.title} - ${item.teacher.first_name}`,
            //             start: item.start_date,
            //             end: item.end_date,
            //
            //         });
            //     });
            //     return items;
            // },
        },
        mounted() {
            this.getAssignedCourses();
            this.getClass();
            this.getTimeTable();

        },
        methods: {
            openWeekdayDialog() {
                this.weekdayDialog = true;
            },
            openTimeTableDialog(day) {
                this.currentDay = day;
                this.academicClassTimetablePeriod.day = this.currentDay;
                this.timeTableDialog = true;
            },

            getClass() {
                academicClassService.show(this.classId).then((response) => {
                    this.class = response.data.class;
                    this.academic_class = response.data.class;
                    this.academicClassTimetable.class_id = this.class.id;
                    this.getTimeTable();

                })
            },
            addTimeTable() {
                this.checkAvailiblity.teacher_id = this.academicClassTimetablePeriod.teacher_id.id;
                this.checkAvailiblity.start_time = this.academicClassTimetablePeriod.start_time;
                this.checkAvailiblity.end_time = this.academicClassTimetablePeriod.end_time;

                this.checkAvailablity();
                if (!this.academicClassTimetablePeriod.is_break) {
                    let subjectId = this.academicClassTimetablePeriod.subject_id.id;
                    let subjectName = this.academicClassTimetablePeriod.subject_id.title;
                    let teacherId = this.academicClassTimetablePeriod.teacher_id.id;
                    let teacherName = this.academicClassTimetablePeriod.teacher_id.full_name;
                    this.academicClassTimetablePeriod.subject_id = subjectId;
                    this.academicClassTimetablePeriod.teacher_id = teacherId;
                    this.academicClassTimetablePeriod.subject_name = subjectName;
                    this.academicClassTimetablePeriod.teacher_name = teacherName;
                }
                this.academicClassTimetablePeriod.day = this.currentDay;
                this.academicClassTimetablePeriods.push(this.academicClassTimetablePeriod);
                this.resetTimeTablePeriod();
            },
            removeTimeTable(index) {
                if (this.academicClassTimetablePeriods[index].id) {
                    this.deleteassignPeriod(this.academicClassTimetablePeriods[index].id);
                }
                this.academicClassTimetablePeriods.splice(index, 1);

            },
            resetTimeTablePeriod() {
                this.academicClassTimetablePeriod = {
                    timetable_id: this.academicClassTimetable.id,
                    day: null,
                    subject_id: null,
                    subject_name: null,
                    teacher_id: null,
                    teacher_name: null,
                    start_time: null,
                    end_time: null,
                    is_break: null,
                }
            },
            resetTimeTable() {
                this.academicClassTimetable = {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 0,
                }
            },

            // checkReapeatTeacherClass(teacherId, courseId) {
            //     const teachersIds = this.assign_course_teachers.filter((item) => {
            //         return item.teacher_id == teacherId;
            //     });
            //     const classIds = this.assign_course_teachers.filter((item) => {
            //         return item.course_id == courseId;
            //     });
            //     if (teachersIds.length > 1 && classIds.length > 1) {
            //         return true;
            //     }
            //     return false;
            //
            // },
            syncCourse() {

                this.assign_course_teacher.course_id = this.academic_classes[this.selected];

            },
            getAssignedCourses() {

                academicClassService.getAssignedCourses(this.classId).then(response => {
                    this.subjects = response.data.courses;


                });
            },
            getTeachers(courseId) {
                academicClassTeacherService.getTeachersByCourseId(courseId.id).then(response => {
                    this.users = response.data.teachers;
                });
            },
            getAcademicTimetablePeriod() {
                academicCLassTimetablePeriod.paginate(this.academicClassTimetable.id).then((resources) => {
                    this.academicClassTimetablePeriods = resources.data.data;
                }).catch(err => {
                })
            },
            checkAvailablity() {

                academicCLassTimetablePeriod.checkAvailablity(this.academicClassTimetable.id, this.checkAvailiblity).then((resources) => {
                }).catch(err => {
                })
            },
            getAssignedClassCourseTeacher() {
                academicClassTeacherService.paginate().then((response) => {
                    this.assign_course_teachers = response.data.data;
                    const teachersIds = this.assign_course_teachers.filter((item) => {
                        return item.teacher_id == 938;
                    });
                })
            },
            deleteassignPeriod(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            academicCLassTimetablePeriod
                                .delete(this.academicClassTimetable.id, id)
                                .then(response => {
                                    this.$snotify.success("Information deleted");

                                    this.getAcademicTimetablePeriod();
                                })
                                .catch(error => {
                                });
                        }
                    }
                });
            },
            getTimeTable() {
                this.getAssignedCourses();
                academicClassTimetable.getByClass(this.class.id).then((response) => {
                    this.academicClassTimetable = response.data.academicClassTimetable;
                    this.getAcademicTimetablePeriod();

                })
            },
            saveTimeTable() {
                this.academicClassTimetable.id ? this.updateTimeTable() : this.createTimeTable();
            },
            createTimeTable() {
                academicClassTimetable.store(this.academicClassTimetable).then((response) => {
                    this.academicClassTimetable = response.data.status;
                    this.weekdayDialog = false;
                    this.academicClassTimetablePeriod.timetable_id = this.academicClassTimetable.id;
                    this.$snotify.success("Information updated");
                }).catch((err) => {
                })
            },
            updateTimeTable() {
                academicClassTimetable.update(this.academicClassTimetable.id, this.academicClassTimetable).then((response) => {
                    this.weekdayDialog = false;
                    this.getTimeTable();
                    this.$snotify.success("Information updated");
                }).catch((err) => {
                })
            },
            saveTimeTablePeriod() {
                this.academicClassTimetablePeriods = this.academicClassTimetablePeriods.map(period => {
                    delete period.simple_start_time;
                    delete period.simple_end_time;
                    delete period.rooms;
                    if (!Object.keys(period).includes("id"))
                        return period;
                })
                academicCLassTimetablePeriod.store(this.academicClassTimetable.id, this.academicClassTimetablePeriods).then((response) => {
                    this.$snotify.success("Information updated");
                    this.timeTableDialog = false;
                    this.getAcademicTimetablePeriod()


                }).catch(err => {
                })
            },
            save() {
                this.assign_course_teacher.course_id = this.academic_classes[this.selected].id;
                if (this.checkReapeatTeacherClass(this.assign_course_teacher.teacher_id, this.assign_course_teacher.course_id)) {
                    this.$confirm({
                        message: `Previous courses & teachers detected, confirm reassign?`,
                        button: {
                            no: "No",
                            yes: "Yes"
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                academicClassTeacherService.create(this.assign_course_teacher).then((response) => {
                                    this.$snotify.success("Information updated");
                                    this.getAssignedClassCourseTeacher();
                                })
                            }
                        }
                    });
                } else {
                    academicClassTeacherService.create(this.assign_course_teacher).then((response) => {
                        this.$snotify.success("Information updated");
                        this.getAssignedClassCourseTeacher();
                    })
                }


            },
        }
    }
</script>

<style scoped>

</style>
