import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class AcademicClassService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/academic-class`;

    }

    getByProgramLevel(academicYearId, programId, gradeId) {
        let url = `${this.#api}/classrooms/${academicYearId}/${programId}/${gradeId}`;
        return apiService.get(url);
    }

    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    paginate(data, index = null) {
        let url = `${this.#api}`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    checkIfStudentExists(classId){
        let url = `${this.#api}/${classId}/check/student-exists`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    toogleClassConfirm(id, data) {
        let url = `${this.#api}/${id}/toogle-class-confirm`
        return apiService.post(url, data);

    }

    updateClassCourse(classCourseId, data) {
        // academic-class/assigned/{classCourseId}/class-course-update
        let url = `${this.#api}/assigned/${classCourseId}/class-course-update`
        return apiService.post(url, data);

    }


    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    syncCourses(id, data) {
        let url = `${this.#api}/${id}/sync-courses`
        return apiService.post(url, data);

    }

    syncStudents(id, data) {
        let url = `${this.#api}/${id}/sync-students`
        return apiService.post(url, data);

    }

    syncTeachers(id, data) {
        let url = `${this.#api}/${id}/sync-teachers`
        return apiService.post(url, data);

    }

    upgradeClass(data) {
        let url = `${this.#api}/upgrade`
        return apiService.post(url, data);
    }

    syncTeacherCourse(userId, data) {
        let url = `${API_URL}user/teacher/${userId}/courser/sync-teacher-course`
        return apiService.post(url, data);

    }


    getAssignedCourses(classId) {
        let url = `${this.#api}/assigned/${classId}/courses`
        return apiService.get(url);
    }

    getAssignedStudents(classId) {
        let url = `${this.#api}/assigned/${classId}/students`
        return apiService.get(url);
    }

    getClassStudents(classId) {
        let url = `${this.#api}/assigned/${classId}/all-students`
        return apiService.get(url);
    }

    getAssignedTeachers(classId) {
        let url = `${this.#api}/assigned/${classId}/teachers`
        return apiService.get(url);
    }

    getAssignedTeachersByCourse(classId, courseId) {
        let url = `${this.#api}/assigned/${classId}/course/${courseId}/teachers`
        return apiService.get(url);
    }

    getAssignedTeacherProgram(userId) {
        let url = `${API_URL}user/teacher/${userId}/assigned/programs`;
        return apiService.get(url);
    }

    getCourseByProgramId(programId) {
        let url = `${API_URL}user/program/${programId}/grade-courses`;
        return apiService.get(url);
    }

    getAssignedTeacherCourses(userId) {
        let url = `${API_URL}user/teacher/${userId}/assigned/courses`;
        return apiService.get(url);
    }

    getAssignedTeacherCoursesList(userId) {
        let url = `${API_URL}user/teacher/${userId}/courses-list`;
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    generateFee(id, data) {
        let url = `${this.#api}/${id}/generate-fee`
        return apiService.post(url, data);
    }

    getStudentsForClassAssign(classId, data) {
        let param = {
            params: data
        }
        let url = `${this.#api}/get-students/${classId}/to-assign`
        return apiService.query(url, param);
    }

    saveStudentAssignCourses(academic_class_id, students) {
        let url = `${this.#api}/${academic_class_id}/save-student/courses`
        return apiService.post(url, students);
    }
    saveStudentCourses(academic_class_id, data) {
        let url = `${this.#api}/${academic_class_id}/save/student/courses`
        return apiService.post(url, data);
    }

    sendWelcomeMail(classId) {
        let url = `${this.#api}/${classId}/welcome-mail/send`
        return apiService.get(url);
    }
    graduatedClassroom(data){
        let url = `${this.#api}/graduation`;
        return apiService.post(url, data);
    }

    search(data={}){
        let url = `${this.#api}/search/all`;
        let param = {params: data};
        return apiService.query(url, param);
    }
}
